import { useState } from 'react'
import Layout from '../components/Layout'
import title from '../images/coin/title.png'
import desIcon from '../images/coin/desIcon.png'
import charts from '../images/coin/charts.png'
import whyFirst from '../images/coin/whyFirst.png'
import whySecond from '../images/coin/whySecond.png'
import whyThird from '../images/coin/whyThird.png'
import whyFourth from '../images/coin/whyFourth.png'
import howToGet from '../images/coin/howToGet.png'
import exchange from '../images/coin/exchange.png'
import privacy from '../images/coin/privacy.png'
import mintingIcon from '../images/coin/mintingIcon.png'
import exchangeIcon from '../images/coin/exchangeIcon.png'
import CreateCoinModal from '../components/CreateCoinModal'
import CreateNFTModal from '../components/CreateNFTModal'

const Coin: React.FC = () => {
  // Create Coin
  const [createCoinModalVisible, setCreateCoinModalVisible] = useState(false)
  const onCreateCoinModalOk = () => {
    document.body.style.overflow = 'auto'
    setCreateCoinModalVisible(false)
  }
  const onCreateCoinModalCancel = () => {
    document.body.style.overflow = 'auto'
    setCreateCoinModalVisible(false)
  }
  // Create NFT
  const [createNFTModalVisible, setCreateNFTModalVisible] = useState(false)
  const onCreateNFTModalOk = () => {
    document.body.style.overflow = 'auto'
    setCreateNFTModalVisible(false)
  }
  const onCreateNFTModalCancel = () => {
    document.body.style.overflow = 'auto'
    setCreateNFTModalVisible(false)
  }
  return (
    <Layout>
      <div className="font-regularText">
        {/* Title */}
        <div className="flex justify-between items-center mt-20 pt-[83px] pb-20 mx-auto max-w-7xl md:px-16 sm:mt-[57px] sm:px-4 sm:flex-col sm:pt-[56px] sm:pb-14">
          <div className="flex flex-col justify-center sm:items-center sm:text-center">
            <div className="text-[#1E94FC] text-[18px] leading-[21px]">
              ChainTokenomics（CTIO）
            </div>
            <div className="mt-3 text-[50px] font-boldText leading-[70px] max-w-[612px] sm:text-4xl">
              You can make your own NFT Tokens
            </div>
            <div className="mt-[22px] text-[#333] max-w-[380px] sm:text-sm">
              Apply for your own branded cryptocurrency—no technical knowledge
              necessary.
            </div>
            <div className="mt-[52px] flex sm:mt-8">
              <button
                className="w-[144px] h-[50px] text-[14px] shadow-[0px_16px_24px_1px_rgba(0,0,0,0.04)] rounded-[60px] text-white bg-gradient-to-r from-[rgba(100,181,246,0.7)] to-[rgba(30,136,229,1)] flex justify-center items-center coin-minting-btn hover:shadow-[0_6px_18px_4px_#c0e2ff]"
                onClick={() => {
                  document.body.style.overflow = 'hidden'
                  setCreateCoinModalVisible(true)
                }}
              >
                Minting
                <img
                  src={mintingIcon}
                  alt="mintingIcon"
                  className="w-4 h-4 ml-2"
                />
              </button>
              <button
                className="w-[144px] h-[50px] text-[14px] shadow-[0px_16px_24px_1px_rgba(0,0,0,0.04)] rounded-[60px] text-white ml-5 sm:ml-[10px] flex justify-center items-center coin-exchange-btn hover:shadow-[0_6px_18px_4px_#efd6d7]"
                onClick={() => {
                  document.body.style.overflow = 'hidden'
                  setCreateNFTModalVisible(true)
                }}
              >
                Exchange
                <img
                  src={exchangeIcon}
                  alt="exchangeIcon"
                  className="w-4 h-4 ml-2"
                />
              </button>
            </div>
          </div>
          <img
            src={title}
            alt="titleImg"
            className="w-[330px] h-[371px] sm:w-[190px] sm:h-[214px] sm:mt-10"
          />
        </div>
        {/* Info Card */}
        <div className="bg-gradient-to-br from-[rgba(146,206,255,0.2)] to-[rgba(248,117,117,0.15)]">
          <div className="grid h-full grid-cols-3 py-16 mx-auto gap-x-6 gap-y-0 max-w-7xl md:px-16 sm:px-4 md:grid-cols-2 sm:grid-cols-1 sm:gap-y-4 sm:gap-x-0 sm:py-6">
            <div className="col-span-1 h-[310px] bg-white shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] rounded-2xl pt-8 px-9 md:col-span-1 sm:grid-span-1 sm:pt-6 sm:px-4 sm:h-[260px]">
              {/* Title */}
              <div className="h-[50px] flex justify-between">
                <div className="flex items-center">
                  <div className="cursor-pointer">$CTIO</div>
                  <div className="before:border-l text-[#BDBDBD] before:mx-4 cursor-pointer">
                    $ETH
                  </div>
                </div>
                <div className="font-numberText flex cursor-pointer items-center text-[#D1D1D1] hover:text-[#333]">
                  <div>24H</div>
                  <svg
                    className="w-3 h-3"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
              {/* Content */}
              <div className="mt-[26px] flex justify-between items-center sm:mt-[8px]">
                <div className="flex items-center">
                  <img
                    src={desIcon}
                    alt="desIcon"
                    className="w-[52px] h-[52px]"
                  />
                  <div className="ml-[15px] flex flex-col">
                    <div className="text-[#828282] text-sm">Value</div>
                    <div className="text-[36px] leading-[36px] sm:text-[26px] sm:leading-[30px]">
                      $1,020.46
                    </div>
                  </div>
                </div>
                <div className="font-numberText text-[#3AD29F]">+55.36%</div>
              </div>
              {/* Desc */}
              <div className="mt-[40px] flex text-[14px]">
                <div className="flex-1">
                  <div className="text-[#828282] mb-2">
                    Token Supply
                    <i className="ml-2 text-[#333333] font-numberText">36</i>
                  </div>
                  <div className="text-[#828282]">
                    Transactions
                    <i className="ml-2 text-[#333333] font-numberText">36</i>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="text-[#828282] mb-2">
                    Addresses
                    <i className="ml-2 text-[#333333] font-numberText">306</i>
                  </div>
                  <div className="text-[#828282]">
                    Total Trading Pairs
                    <i className="ml-2 text-[#333333] font-numberText">566</i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-2 h-[310px] bg-white shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] md:col-span-1 rounded-2xl pt-8 px-9 sm:grid-span-1 sm:pt-7 sm:px-4 sm:h-[260px]">
              {/* Title */}
              <div className="h-[50px] flex justify-between items-center">
                <div className="font-numberText text-[36px] leading-9 sm:text-[28px]">
                  $1,020.46
                </div>
                <div className="font-numberText flex cursor-pointer items-center text-[#D1D1D1] hover:text-[#333]">
                  <div>24H</div>
                  <svg
                    className="w-3 h-3"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
              {/* Time */}
              <div className="text-[#828282] text-xs">2022/03/21 08:00:00</div>
              {/* Charts */}
              <div className="mt-[13px] sm:mt-5">
                <img src={charts} alt="charts" />
              </div>
            </div>
          </div>
        </div>
        {/* Table Card */}
        <div className="max-w-7xl bg-white py-[84px] mx-auto md:px-16 sm:px-4 sm:py-6 sm:bg-[#F8F8F8]">
          {/* Title */}
          <div className="h-[72px] flex justify-between items-center sm:hidden">
            <div className="flex text-[26px] sm:text-[18px]">
              <div className="font-boldText">Top Market</div>
              <div className="before:border-l before:mx-[28px] text-[#828282]">
                Latest Market
              </div>
            </div>
            <div className="w-[444px] h-[52px] mr-[10px] relative md:w-[238px]">
              <input
                type="text"
                className="w-full h-full py-[17px] pl-[21px] pr-[50px] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] bg-white rounded-[60px] text-[14px] text-[#BDBDBD] placeholder:text-[#BDBDBD] border border-[#F2F2F2] outline-none"
                placeholder="Search"
              />
              <div className="text-[#4f4f4f] absolute right-[21px] top-1/2 -translate-y-1/2 cursor-pointer">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
          {/* PC Table Content */}
          <div className="bg-white mt-[26px] font-numberText shadow-[0px_16px_24px_1px_rgba(0,0,0,0.04)] sm:mt-0 sm:bg-transparent">
            <div className="flex h-[66px] text-white bg-[#16181E] rounded-2xl sm:hidden">
              {[
                'Currency',
                'Price',
                'Pool Liquidity',
                '24H Volume',
                '24H Tx Fee',
                'Fee APY',
                'Operation',
              ].map((x, i) => (
                <div className="flex items-center justify-center flex-1">
                  {x}
                  {[2, 3, 4, 5].includes(i) && (
                    <svg
                      className="w-3 h-3 ml-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </div>
              ))}
            </div>
            {/* FIXME: Just Modify Data */}
            {new Array(5)
              .fill({
                Currency: '$CTIO',
                Price: '$0.061271',
                'Pool Liquidity': '$347,437.23',
                '24H Volume': '$557.06',
                '24H TxFee': '$2.7853',
                FeeAPY: '+0.45%',
                Operation: 'Purchase',
              })
              .map(x => (
                <div className="flex h-20 border-b border-[#E2E2E2] border-solid sm:border-none sm:mb-4 sm:bg-[#fff] sm:p-5 sm:grid sm:grid-cols-2 sm:h-auto sm:gap-y-4 justify-start items-center sm:shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] sm:rounded-lg last:mb-0">
                  {Object.keys(x).map((key, i, allKey) => (
                    <>
                      <div className="hidden sm:block font-regularText ">
                        {key}
                      </div>
                      <div
                        className={`flex items-center justify-center flex-1 first:font-regularText sm:justify-start ${
                          i === 5
                            ? 'text-[#3AD29F]'
                            : 'text-[#333] sm:text-[#828282]'
                        }`}
                      >
                        {i !== allKey.length - 1 ? (
                          x[key]
                        ) : (
                          <button className="w-[92px] h-[39px] border border-[#828282] text-[#828282] rounded-[20px] sm:w-[76px] sm:h-[30px] sm:text-xs">
                            {x[key]}
                          </button>
                        )}
                      </div>
                    </>
                  ))}
                </div>
              ))}
          </div>
        </div>
        {/* Why $token in CTIO Card */}
        <div className="w-full bg-[#16181E] md:px-16 sm:px-4">
          <div className="max-w-7xl mx-auto pt-[84px] pb-16 sm:pt-16 sm:pb-[52px]">
            <div className="font-boldText text-white text-[50px] leading-[64px] text-center sm:text-[28px] sm:leading-[50px]">
              Why $token in CTIO
            </div>
            <div className="grid justify-center grid-cols-4 gap-5 mt-[52px] overflow-hidden sm:mt-5 sm:grid-cols-1">
              {[
                {
                  img: whyFirst,
                  title: 'Our Native Token $CTIO',
                  desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla.',
                },
                {
                  img: whySecond,
                  title: 'Digital Value Chain Token',
                  desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla.',
                },
                {
                  img: whyThird,
                  title: ' Exchange $CTIO in DEXs',
                  desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla.',
                },
                {
                  img: whyFourth,
                  title: ' Terms and Conditions',
                  desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla.',
                },
              ].map(x => (
                <div className="flex flex-col h-[389px] bg-black rounded-2xl overflow-hidden">
                  <img src={x.img} alt="why" className="w-full" />
                  <div className="flex-1 pt-8 px-[35px] text-center bg-white md:p-6">
                    <div className="text-xl leading-[32px] font-boldText mb-2">
                      {x.title}
                    </div>
                    <div className="text-sm text-[#828282]">{x.desc}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* How to get $CTIO */}
        <div className="mx-auto mt-[84px] max-w-7xl md:px-16 sm:px-4 sm:mt-0">
          <div className="flex flex-col">
            <div className="flex items-center justify-between sm:flex-col-reverse sm:pt-6">
              <div className="flex-1 h-[162px] max-w-[677px] sm:text-center">
                <div className="font-boldText text-[50px] leading-[64px] mb-6 sm:text-[26px] sm:leading-[34px] sm:mb-4">
                  How to get $CTIO
                </div>
                <div className="text-[#4F4F4F]">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Pretium, arcu ipsum, quam magna. A amet, pretium, quam blandit
                  consectetur. Mauris amet risus tortor libero purus, porta sed
                  non mi. Sapien placerat mi pharetra lacus in lobortis proin.
                </div>
              </div>
              <img
                src={howToGet}
                alt="howToGet"
                className="w-[355px] h-full sm:w-[270px] sm:h-[270px]"
              />
            </div>
            {/* Other */}
            <div className="grid grid-cols-2 gap-5 mt-[58px] sm:mt-[50px] sm:grid-cols-1 sm:gap-6">
              {/* Exchange */}
              <div className="bg-[linear-gradient(180deg,#E4F4FD_0%,#E4F4FD_100%)] rounded-2xl py-[35px] px-[37px] flex items-center justify-between sm:flex-col sm:p-0 sm:text-center">
                <div className="w-[287px] h-[89px] sm:h-auto sm:w-auto">
                  <div className="text-[#1E94FC] text-2xl leading-9 mb-6 sm:mt-[35px] sm:font-boldText sm:mb-2">
                    Exchange at any time
                  </div>
                  <div className="text-[#828282] sm:text-sm sm:px-4 mb-[13px]">
                    Exchange of any currency combination is supported
                  </div>
                </div>
                <img
                  src={exchange}
                  alt="exchange"
                  className="w-[220px] h-[220px] sm:w-[196px] sm:h-[196px] sm:mb-[13px]"
                />
              </div>
              {/* Privacy */}
              <div className="bg-[#FFF8F8] rounded-2xl py-[35px] px-[37px] flex items-center justify-between sm:flex-col sm:p-0 sm:text-center">
                <div className="w-[287px] h-[89px] sm:h-auto sm:w-auto">
                  <div className="text-[#1E94FC] text-2xl leading-9 mb-6 sm:mt-[35px] sm:font-boldText sm:mb-2">
                    Privacy and Policy
                  </div>
                  <button className="w-[110px] h-[44px] text-[#4F4F4F] text-[12px] rounded-[60px] border border-[#828282]">
                    Read detail
                  </button>
                </div>
                <img
                  src={privacy}
                  alt="privacy"
                  className="w-[220px] h-[220px] sm:w-[196px] sm:h-[196px] sm:mb-[13px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Open Modal */}
      <CreateCoinModal
        visible={createCoinModalVisible}
        onOk={onCreateCoinModalOk}
        onCancel={onCreateCoinModalCancel}
      />
      <CreateNFTModal
        visible={createNFTModalVisible}
        onOk={onCreateNFTModalOk}
        onCancel={onCreateNFTModalCancel}
      />
    </Layout>
  )
}

export default Coin
