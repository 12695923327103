import { useState } from 'react'
import Switch from './Switch'
type CreateCoinModalPropsType = {
  visible: Boolean
  onOk(): void
  onCancel(): void
}
/**
 * @param visible: Whether the modal dialog is visible or not
 * @callback onOk: Specify a function that will be called when a user clicks the OK button
 * @callback onCancel: Specify a function that will be called when a user clicks mask, close button on top right or Cancel button
 * @returns
 */
const CreateCoinModal: React.FC<CreateCoinModalPropsType> = ({
  visible,
  onOk,
  onCancel,
}) => {
  const [additionalOptionList, setAdditionalOptionList] = useState([
    {
      title: 'Can Burn',
      name: 'canBurn',
      checked: false,
      disabled: false,
    },
    {
      title: 'Can Mint',
      name: 'canMint',
      checked: false,
      disabled: false,
    },
    {
      title: 'Can Pause',
      name: 'canPause',
      checked: false,
      disabled: false,
    },
    {
      title: 'Can Blacklist',
      name: 'canBlacklist',
      checked: false,
      disabled: false,
    },
    {
      title: 'Change Owner',
      name: 'changeOwner',
      checked: false,
      disabled: false,
    },
  ])
  return (
    <div className="overflow-hidden font-regularText">
      <div
        className={`py-20 overflow-auto w-full h-screen top-0 left-0 fixed transition-all z-[999] backdrop-saturate-[180%] backdrop-blur-[3px] bg-[rgba(255,255,255,0.75)] sm:py-0  ${
          visible ? 'flex' : 'hidden'
        }`}
      >
        <div
          className={`w-[640px] m-auto bg-gradient-to-t from-[rgba(255,255,255,1)] to-[rgba(234,244,252,0.71)] shadow-[0_0_24px_1px_rgba(0,0,0,0.1)] border border-[#fff] border-solid rounded-2xl p-10 relative sm:w-auto sm:px-6 sm:pt-[26px] sm:pb-[48px] sm:rounded-none`}
        >
          {/* Mobile Title */}
          <div className="hidden h-[30px] sm:grid grid-cols-3 items-center mb-[34px] cursor-pointer">
            <svg
              className="w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              onClick={() => onCancel()}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19l-7-7 7-7"
              />
            </svg>
            <div className="text-[22px] leading-[30px] justify-self-center font-boldText">
              $CTIO
            </div>
          </div>
          {/* Modal Content */}
          <div className="font-boldText text-[34px] leading-[52px] sm:leading-10">
            Create Your Coin
          </div>
          <form className="mt-[33px]">
            <div className="flex flex-col">
              <label
                htmlFor="coinName"
                className="relative mb-2 text-sm after:content-['*'] after:w-1 after:h-4 after:text-red-500 after:ml-1 sm:text-[18px] sm:leading-[30px]"
              >
                Coin name
              </label>
              <input
                name="coinName"
                type="text"
                id="coinName"
                className="h-[52px] bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] outline-none p-4 text-[#828282] text-sm placeholder:text-[#828282]"
                placeholder="e.g. Nice Coin Name"
              />
              <label
                htmlFor="symbo"
                className="relative mb-2 text-sm after:content-['*'] after:w-1 after:h-4 after:text-red-500 after:ml-1 mt-8 sm:text-[18px] sm:leading-[30px]"
              >
                Symbo
              </label>
              <input
                name="symbo"
                type="text"
                id="symbo"
                className="h-[52px] bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] outline-none p-4 text-[#828282] text-sm placeholder:text-[#828282]"
                placeholder="e.g. NT"
              />
              <div className="flex gap-6 mt-8 sm:flex-col sm:gap-[33px]">
                <div>
                  <label
                    htmlFor="initialSupply"
                    className="relative mb-2 text-sm after:content-['*'] after:w-1 after:h-4 after:text-red-500 after:ml-1 sm:text-[18px] sm:leading-[30px]"
                  >
                    Initial Supply
                  </label>
                  <input
                    name="initialSupply"
                    type="number"
                    id="initialSupply"
                    className="h-[52px] w-full bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] outline-none p-4 text-[#828282] text-sm placeholder:text-[#828282]"
                    placeholder="e.g. 168000000"
                  />
                </div>
                <div>
                  <label
                    htmlFor="decimals"
                    className="relative mb-2 text-sm after:content-['*'] after:w-1 after:h-4 after:text-red-500 after:ml-1 sm:text-[18px] sm:leading-[30px]"
                  >
                    Decimals (1-18)
                  </label>
                  <input
                    name="decimals"
                    type="number"
                    id="decimals"
                    className="h-[52px] w-full bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] outline-none p-4 text-[#828282] text-sm placeholder:text-[#828282]"
                    placeholder="e.g. 18"
                  />
                </div>
              </div>
            </div>
            <div className="mt-[33px] sm:mt-[45px]">
              <div className="text-[22px] font-boldText sm:leading-[30px]">
                Additional options
              </div>
              <div className="grid grid-cols-3 gap-4 mt-6 sm:grid-cols-1 sm:gap-0 sm:mt-0">
                {additionalOptionList.map(x => (
                  <div className="flex items-center sm:h-[86px] sm:justify-between sm:border-b sm:border-[#F2F2F2] sm:border-solid last:sm:border-none">
                    <Switch
                      classname="sm:order-last"
                      checked={x.checked}
                      onChange={() =>
                        setAdditionalOptionList(
                          additionalOptionList.map(item =>
                            item !== x
                              ? item
                              : { ...item, checked: !item.checked },
                          ),
                        )
                      }
                      disabled={x.disabled}
                    />
                    <div className="flex items-center">
                      <div className="mx-2 text-sm whitespace-nowrap">
                        {x.title}
                      </div>
                      <svg
                        className="w-4 h-4 text-[#848484] cursor-pointer"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-[33px] bg-[#EDF7FF] rounded p-5 text-[#4F4F4F] text-xs leading-5 sm:mt-0 sm:p-[14px] sm:pb-4 sm:text-sm">
              It takes approximately 10 seconds to create the tokens after you
              confirm. The token will be automatically transferred to the
              address of the creator/owner after the creation is successful. The
              token is automatically deployed to the network and verified.
            </div>
            <button
              className="bg-[linear-gradient(270deg,#1E94FC_0%,#AED8FF_100%)] rounded w-[188px] h-[54px] text-white mt-[33px] sm:w-full"
              onClick={e => {
                e.preventDefault()
                onOk()
              }}
            >
              Create Coin
            </button>
          </form>
          {/* Close Icon */}
          <div
            className="absolute top-4 right-5 text-[#BDBDBD] cursor-pointer sm:hidden"
            onClick={() => onCancel?.()}
          >
            <svg
              className="w-[26px] h-[26px]"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateCoinModal
