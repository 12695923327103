import classnames from 'classnames'
type SwitchButtonType = {
  checked: boolean
  onChange: (checked: boolean) => void
  disabled?: boolean
  classname?: string
}

export default function Switch({
  checked,
  classname,
  onChange,
  disabled = false,
}: SwitchButtonType) {
  return (
    <div
      onClick={() => !disabled && onChange(!checked)}
      className={classnames(
        `h-6 w-[46px] rounded-[100px] relative after:absolute after:bg-white after:rounded-[100%] after:w-[18px] after:h-[18px] after:top-[3px] after:transition-all ${
          checked
            ? 'bg-[#1890ff] after:left-[52%] '
            : 'bg-[#f2f2f2] after:left-1 after:bg-[#c4c4c4]'
        } ${disabled ? 'cursor-not-allowed opacity-40' : 'cursor-pointer'}`,
        classname,
      )}
    />
  )
}
