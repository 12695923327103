import { Link } from 'gatsby'
import linkPic from '../images/createNFT/linkPic.png'
type CreateNFTModalPropsType = {
  visible: Boolean
  onOk(): void
  onCancel(): void
}
/**
 * @param visible: Whether the modal dialog is visible or not
 * @callback onOk: Specify a function that will be called when a user clicks the OK button
 * @callback onCancel: Specify a function that will be called when a user clicks mask, close button on top right or Cancel button
 * @returns
 */
const CreateNFTModal: React.FC<CreateNFTModalPropsType> = ({
  visible,
  onOk,
  onCancel,
}) => {
  return (
    <div className="overflow-hidden font-regularText">
      <div
        className={`w-full h-screen top-0 left-0 fixed transition-all ease-linear z-[999] backdrop-saturate-[180%] backdrop-blur-[3px] bg-[rgba(255,255,255,0.75)] sm:bg-[rgba(0,0,0,0.4)] overflow-hidden ${
          visible ? 'flex' : 'hidden'
        } ${visible ? 'sm:flex sm:visible' : 'sm:flex sm:invisible'}`}
      >
        <div
          className={`w-[640px] m-auto bg-gradient-to-t from-[rgba(255,255,255,1)] to-[rgba(234,244,252,0.71)] shadow-[0_0_24px_1px_rgba(0,0,0,0.1)] border border-[#fff] border-solid rounded-2xl p-10 relative sm:fixed sm:bottom-0 sm:rounded-[32px_32px_0_0] sm:w-screen sm:from-[rgba(255,255,255,1)] sm:to-[rgba(234,244,252,0.81)] sm:px-4 sm:py-0 ${
            visible ? 'sm:h-[263px]' : 'sm:h-0'
          } transition-[height] ease-linear`}
        >
          {/* Modal Content */}
          <div className="font-boldText text-[34px] leading-[52px] sm:text-center sm:text-[26px] sm:leading-[34px] sm:mt-[42px]">
            Create Your NFT
          </div>
          <div className="mt-[33px] flex sm:mt-[21px]">
            <img
              src={linkPic}
              alt="linkPicture"
              className="h-[83px] w-[83px]"
            />
            <div className="ml-5 flex justify-center flex-col">
              <div className="text-[18px] leading-[22px]">
                You have to Connect Wallet before creating NFT
              </div>
              <Link
                to="https://ethereum.org/en/dapps/#_3-what-is-a-wallet-and-which-one-should-i-use"
                target="_blank"
                className="mt-[11px] text-[#1E88E5] underline"
              >
                What is a wallet?
              </Link>
            </div>
          </div>
          {/* Close Icon */}
          <div
            className="absolute top-4 right-5 text-[#BDBDBD] cursor-pointer"
            onClick={() => onCancel?.()}
          >
            <svg
              className="w-[26px] h-[26px]"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateNFTModal
